import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Carousel from '../components/carousel'
import cutegirl from '../images/hero-homepage.jpg'

const IndexCard = ({ img, imgalt, title }) => (
  <div className="column">
    <div className="card index--card">
      <div className="index--card-pic">
        <figure className="is-4-by-3">
          <GatsbyImage image={img} alt={imgalt} />
        </figure>
      </div>
      <div className="card-content">
        <h2 className="index--card-content">{title}</h2>
      </div>
    </div>
  </div>
)
const IndexPage = (props) => (
  <Layout>
    <SEO title="Home" />
    <Carousel
      title={<p className="index--title is-uppercase">Smart Native</p>}
      subtitle={
        <p className="index--subtitle is-size-1">
          <FormattedMessage id="index--subtitle-1" />
          <br />
          <FormattedMessage id="index--subtitle-2" />
          <br />
          <FormattedMessage id="index--subtitle-3" />
        </p>
      }
      size={'is-fullheight'}
      xclass={'has-text-centered'}
      img={cutegirl}
      imgalt={'Cute girl'}
    />
    <div className="main">
      <div className="columns has-text-centered">
        <div className="column">
          <iframe
            className="video-container"
            title="Promotional video"
            src="https://www.youtube.com/embed/vPuKaPSEfnQ"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/vPuKaPSEfnQ?autoplay=1><img src=https://img.youtube.com/vi/vPuKaPSEfnQ/hqdefault.jpg alt='Promotional video'><span>&#x25BA;</span></a>"
          ></iframe>
        </div>
        <div className="column">
          <iframe
            className="video-container"
            title="Promotional video"
            src="https://www.youtube.com/embed/ZXGsy3h_6-A"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            srcDoc="<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/ZXGsy3h_6-A?autoplay=1><img src=https://img.youtube.com/vi/ZXGsy3h_6-A/hqdefault.jpg alt='Promotional video'><span>&#x25BA;</span></a>"
          ></iframe>
        </div>
      </div>
      <div>
        <h1 className="index--card-title">
          <span>
            <FormattedMessage id="index--heading1" />
          </span>
        </h1>
        <div className="columns is-desktop is-multiline is-fluid section">
          <IndexCard
            img={props.data.index1.childImageSharp.gatsbyImageData}
            imgalt="phone"
            title={<FormattedMessage id="index--heading1-card1" />}
          />
          <IndexCard
            img={props.data.index2.childImageSharp.gatsbyImageData}
            imgalt="cash"
            title={<FormattedMessage id="index--heading1-card2" />}
          />
          <IndexCard
            img={props.data.index3.childImageSharp.gatsbyImageData}
            imgalt="maple leaf"
            title={<FormattedMessage id="index--heading1-card3" />}
          />
        </div>
        <div className="columns is-desktop is-multiline is-fluid section">
          <IndexCard
            img={props.data.index4.childImageSharp.gatsbyImageData}
            imgalt="teaching plan"
            title={<FormattedMessage id="index--heading1-card4" />}
          />
          <IndexCard
            img={props.data.index5.childImageSharp.gatsbyImageData}
            imgalt="student report"
            title={<FormattedMessage id="index--heading1-card5" />}
          />
          <IndexCard
            img={props.data.index6.childImageSharp.gatsbyImageData}
            imgalt="school"
            title={<FormattedMessage id="index--heading1-card6" />}
          />
        </div>
        <h1 className="index--card-title">
          <span>
            <FormattedMessage id="index--heading2" />
          </span>
        </h1>
        <div className="columns is-desktop is-multiline is-fluid section">
          <IndexCard
            img={props.data.index7.childImageSharp.gatsbyImageData}
            imgalt="skype"
            title={<FormattedMessage id="index--heading2-card1" />}
          />
          <IndexCard
            img={props.data.index8.childImageSharp.gatsbyImageData}
            imgalt="equipment"
            title={<FormattedMessage id="index--heading2-card2" />}
          />
          <IndexCard
            img={props.data.index9.childImageSharp.gatsbyImageData}
            imgalt="teaching material"
            title={<FormattedMessage id="index--heading2-card3" />}
          />
        </div>
      </div>
      <div className="index--footer">
        <h1 className="index--footer-text">
          <FormattedMessage id="index--container-heading" />
        </h1>
        <Link to="/course" aria-label="back">
          <div id="index--footer-button" className="is-size-4">
            <FormattedMessage id="index--container-button" />
          </div>
        </Link>
      </div>
    </div>
  </Layout>
)

export const indexImage = graphql`
  fragment indexImage on File {
    childImageSharp {
      gatsbyImageData(width: 200, height: 200, layout: FIXED)
    }
  }
`

export const query = graphql`
  query {
    cutegirl: file(relativePath: { eq: "hero-homepage.jpg" }) {
      ...indexImage
    }
    index1: file(relativePath: { eq: "index-1.png" }) {
      ...indexImage
    }
    index2: file(relativePath: { eq: "index-2.png" }) {
      ...indexImage
    }
    index3: file(relativePath: { eq: "index-3.png" }) {
      ...indexImage
    }
    index4: file(relativePath: { eq: "index-4.png" }) {
      ...indexImage
    }
    index5: file(relativePath: { eq: "index-5.png" }) {
      ...indexImage
    }
    index6: file(relativePath: { eq: "index-6.png" }) {
      ...indexImage
    }
    index7: file(relativePath: { eq: "index-7.png" }) {
      ...indexImage
    }
    index8: file(relativePath: { eq: "index-8.png" }) {
      ...indexImage
    }
    index9: file(relativePath: { eq: "index-9.png" }) {
      ...indexImage
    }
  }
`

export default IndexPage
